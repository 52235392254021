<!--
 * @Description:首页轮播图
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-06 17:21:01
 * @LastEditors: 孙卫朋 sunweipengEmail
 * @LastEditTime: 2023-12-11 17:59:46
-->
<template>
  <div class="p">
    <el-carousel class="p-carousel" ref="bannerRef" height="100%" :autoplay="true" :interval="5000">
      <!-- 去掉9月活动 -->
      <el-carousel-item class="p-carousel-item" v-if="0 > 1">
        <div class="container-fluid banner-box">
          <div class="banner-box-image">
            <el-image :fit="'cover'" :lazy="false" class="image" src="/image/banner/banner0.png"></el-image>
          </div>
          <!-- opacity: 0.9; background-image: url('/image/index/banner0_icon.png');background-repeat: no-repeat; background-size: auto 100%; background-position: calc(); -->
          <div class="container banner-box-item" style="">
            <div style="height: 283px;">
              <el-image :fit="'scale-down'" :lazy="false" class="image" src="/image/banner/banner0_1.png"></el-image>
              <JzButton class="banner-box-item-info-button" style="height: 40px; width: 160px; position: relative; left: 90px; margin: 0; top: -50px; opacity: 0;" :itemSet="{ 'title': '查看详情' }" :btnProps="{ 'plain': true }" :onCallBack="{ handClick: handClick }" :backInfo="{ 'type': 'login' }"></JzButton>
            </div>
            <div class="banner-box-item-info" style="display:none">
              <div class="banner-box-item-info-title">双·11 金智CRM企业数字狂欢节！</div>
              <div class="banner-box-item-info-subtitle hidden-xs-only" v-html="`<div style='letter-spacing:2px;''><div style='display:none;'><span style=color:yellow>新用户满减优惠金额</span>&ensp;满2000元减1000元</br></div><span style=color:yellow>新老用户满赠产品时间</span>&ensp;满1年送3个月 满2年送1年</div>`"></div>
              <!-- <JzButton class="banner-box-item-info-button" style="width:104px;height:40px;" :itemSet="{ 'title': '查看详情' }" :btnProps="{ 'plain': true }" :onCallBack="{ handClick: handClick }" :backInfo="{ 'type': 'login' }"></JzButton> -->
              <JzButton class="banner-box-item-info-button" style="height: 40px; margin-top: 110px; margin-left: 140px; width: 160px;" :itemSet="{ 'title': '查看详情' }" :btnProps="{ 'plain': true }" :onCallBack="{ handClick: handClick }" :backInfo="{ 'type': 'login' }"></JzButton>
            </div>
            <div style="position: relative;" v-if="0 > 1">
              <div style="height:29px;width:360px;position: absolute; right:100px; top: -200px;">
                <el-image :fit="'scale-down'" :lazy="false" class="image" src="/image/index/banner0_2.png"></el-image>
              </div>
            </div>
            <div style="position: relative;">
              <div style="height:337px;width:393px;position: absolute; right:0px; top: -200px;">
                <el-image :fit="'scale-down'" :lazy="false" class="image" src="/image/index/banner0_icon.png"></el-image>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>

      <el-carousel-item class="p-carousel-item" v-if="0 > 1">
        <div class="container-fluid banner-box">
          <div class="banner-box-image">
            <el-image :fit="'fill'" :lazy="false" class="image" src="/image/banner/promotion_banner_bg.png"></el-image>
          </div>
          <div style="width: 100%; height: 20px;"></div>
          <div class="container banner-box-item" style="height: 450px; ">
            <el-image :fit="'contain'" :lazy="false" class="image" src="/image/banner/promotion_banner_bg1.png"></el-image>
          </div>
        </div>
      </el-carousel-item>

      <el-carousel-item class="p-carousel-item">
        <div class="container-fluid banner-box">
          <div class="banner-box-image">
            <el-image :lazy="false" class="image" src="/image/banner/banner1.png"></el-image>
          </div>
          <div class="container banner-box-item">
            <div class="banner-box-item-info">
              <div class="banner-box-item-info-title">钉钉生态销售全过程管理CRM</div>
              <div class="banner-box-item-info-subtitle hidden-xs-only" v-html="'金智CRM以数据驱动，无缝支持销售全过程，助企业完成</br>移动化、数据化、智能化管理，提升企业盈利能力。'"></div>
              <JzButton class="banner-box-item-info-button" style="width:104px;height:40px;" :itemSet="{ 'title': '查看详情' }" :btnProps="{ 'plain': true }" :onCallBack="{ handClick: handClick }" :backInfo="{ 'msgid': 111 }"></JzButton>
            </div>
            <div class="banner-box-item-img">
              <el-image :lazy="false" src="/image/index/b1.png"></el-image>
            </div>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item class="p-carousel-item">
        <div class="container-fluid banner-box">
          <div class="banner-box-image">
            <el-image class="image" src="/image/banner/banner2.png"></el-image>
          </div>
          <div class="container banner-box-item">
            <div class="banner-box-item-info">
              <div class="banner-box-item-info-title">金智CRM
                <span class="hidden-sm-and-up"><br /></span>
                <span class="hidden-xs-only">·</span> 智能版
              </div>
              <div class="banner-box-item-info-subtitle hidden-xs-only" v-html="'适用于中大型企业，多业生态场景，表单Paas搭建丰富应用规则与</br>智能统计报表。'"></div>
              <div style="width:104px;height:40px;"></div>
              <JzButton class="banner-box-item-info-button" style="width:104px;height:40px;display: none;" :itemSet="{ 'title': '查看详情' }" :btnProps="{ 'plain': true }" :onCallBack="{ handClick: handClick }" :backInfo="{ 'msgid': 111 }"></JzButton>
            </div>
            <div class="banner-rightImg">
              <div class="banner-rightImg-img imgLeft">
                <el-image class="image" src="/image/index/banner2-2.svg"></el-image>
              </div>
              <div class="banner-rightImg-img imgRight">
                <el-image class="image" src="/image/index/banner2-1.svg"></el-image>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
      <div class="">
      </div>
    </el-carousel>
  </div>
</template>

<script>
import { defineComponent, onMounted, watch, ref, reactive, toRefs, getCurrentInstance, } from "vue";
import JzButton from '@/components/JzButton/JzButton.vue'
import { useRouter } from 'vue-router'
export default {
  components: { JzButton },
  name: 'banner',
  props: {
    info: {
      type: [Array, Object]
    }
  },
  setup(props, context) {
    const $router = useRouter();
    const bannerRef = ref(null);
    const handClick = (e, info) => {
      if (info && info.type) {
        $router.push({
          path: '/trial',
        })
      } else {
        $router.push({
          path: '/info',
          query: info
        })
      }
    }
    const slideBanner = () => {
      //选中的轮播图
      var box = document.querySelector('.el-carousel__container');
      var startPointX = 0
      var stopPointX = 0
      //重置坐标
      var resetPoint = function () {
        startPointX = 0
        stopPointX = 0
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPointX = e.changedTouches[0].pageX
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPointX = e.changedTouches[0].pageX
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function (e) {
        if (stopPointX == 0 || startPointX - stopPointX == 0) {
          resetPoint()
          return
        }
        if (startPointX - stopPointX > 0) {
          resetPoint()
          bannerRef.value.next()
          return
        }
        if (startPointX - stopPointX < 0) {
          resetPoint()
          bannerRef.value.prev()
          return
        }
      });
    }
    onMounted(() => {
      slideBanner();
    })

    return {
      handClick,
      bannerRef,
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.p {
  &-carousel {
    height: 590px;
  }

  .banner {
    &-box {
      height: 100%;
      position: relative;

      // 背景图
      &-image {
        top: 0px;
        left: 0px;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(#3cb7fe, #1267fe);
      }

      // 内容
      &-item {
        height: 100%;
        position: relative;
        z-index: 4;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-info {
          color: #ffffff;
          margin-top: -36px;

          &-title {
            padding: 8px 16px;
            font-size: 36px;
            font-weight: 700;

            height: 70px;
            overflow: hidden;
            // 显示动画
            animation-name: showTitle;
            animation-duration: 0.5s;
            animation-timing-function: ease;
            animation-direction: none;
          }

          &-subtitle {
            position: relative;
            padding: 8px 16px;
            font-size: 18px;

            height: 70px;
            overflow: hidden;
            // 显示动画
            animation-name: showTitle;
            animation-duration: 0.5s;
            animation-timing-function: ease;
            animation-direction: none;
          }

          // 查看详细
          &-button {
            margin-top: 56px;
            margin-left: 16px;
            background-color: transparent;
            color: #ffffff;

            height: 70px;
            overflow: hidden;
            // 显示动画
            animation-name: showTitle;
            animation-duration: 0.5s;
            animation-timing-function: ease;
            animation-direction: none;
          }
        }

        &-img {
          height: 100%;
          width: 20%;
          margin-top: 300px;
        }
      }
    }
  }

  // banner 右侧图标
  .banner-rightImg {
    width: 400px;
    position: relative;
    z-index: 4;

    &-img {
      width: 220px;
      height: 220px;
      position: relative;
    }

    .imgLeft {
      top: 40px;
      left: -20px;
    }

    .imgRight {
      width: 260px;
      height: 260px;
      right: -100px;
      top: -60px;
    }
  }

  ::v-deep(.el-carousel__indicators--horizontal) {
    padding-bottom: 130px;
  }

  ::v-deep(.el-carousel__button) {
    height: 6px;
  }
}

// 动画
@keyframes showTitle {
  0% {
    padding-top: 20px;
  }

  100% {
  }
}

// 媒体查询
@media (max-width: 767px) {
  .p {
    &-carousel {
      margin-top: 24px;
      height: 183px;
      width: 95%;
      margin-left: 2.5%;
      border-radius: 8px;

      ::v-deep(.el-carousel__indicators--horizontal) {
        padding-bottom: 10px;
      }

      .banner {
        &-box {
          &-item {
            &-img {
              height: 70%;
              width: 50%;
            }

            &-info {
              margin-top: 0px;
              position: relative;
              z-index: 6;

              &-title {
                width: 100%;
                font-size: 22px;
                padding-left: 0px;
              }

              &-button {
                margin-top: 20px;
                width: 94px !important;
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  }

  // banner 右侧图标
  .banner-rightImg {
    width: 100px !important;
    position: relative;
    z-index: 10;

    &-img {
      width: 120px !important;
      height: 120px !important;
      position: relative;
    }

    .imgLeft {
      top: 40px !important;
      left: -40px !important;
    }

    .imgRight {
      width: 60px;
      height: 60px;
      right: -20px !important;
      top: -40px !important;
    }
  }

  ::v-deep .el-carousel__arrow {
    display: none;
  }
  // 圆点样式
  ::v-deep
    .home-banner
    .el-carousel__indicator--horizontal
    .el-carousel__button {
    width: 6px;
    height: 6px;
    background: transparent;
    background: #e5e5e5;
    border-radius: 50%;
    opacity: 1;
  }
  ::v-deep
    .home-banner
    .el-carousel__indicator--horizontal.is-active
    .el-carousel__button {
    width: 6px;
    height: 6px;
    background: #235fb0;
    border-radius: 50%;
    opacity: 1;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
}

@media (min-width: 993px) and (max-width: 1200px) {
}

@media (min-width: 1201px) {
}
</style>
